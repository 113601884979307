import Vue from "vue";
import VueRouter from "vue-router";
import Colaborador from "../views/Colaborador.vue";
import Clientes from "../views/Clientes.vue";
import General from "../views/General.vue";
import vue5990 from "../views/5990.vue";
import vue5690 from "../views/5690.vue";
import vue5490 from "../views/5490.vue";
import vue5190 from "../views/5190.vue";
import vue4990 from "../views/4990.vue";
// import vue5990noCode from "../views/5990noCode.vue";
// import vue5690noCode from "../views/5690noCode.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/NqKa5MHGvHuMko5/inicio",
  },
  // {
  //   path: "/colaborador/inicio",
  //   name: "Colaboradors",
  //   component: Colaborador,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/clientes/inicio",
  //   name: "Clientess",
  //   component: Clientes,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/5990/inicio",
  //   name: "5990Clientess",
  //   component: vue5990noCode,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/5690/inicio",
  //   name: "5690-Clientess",
  //   component: vue5690noCode,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  {
    path: "/NqKa5MHGvHuMko5/inicio",
    name: "5990-Clientess",
    component: vue5990,
    meta: {
      title: 'SOAP BANCOESTADO', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP BANCOESTADO'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  // {
  //   path: "/toCSq1UEhGaZp4z/inicio",
  //   name: "5690Clientess",
  //   component: vue5690,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/d6uaCqohiUk0UWW/inicio",
  //   name: "5490Clientess",
  //   component: vue5490,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/MlHaYAwEjhNPs5j/inicio",
  //   name: "5190Clientess",
  //   component: vue5190,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/vgqjYnJGvLfI6iO/inicio",
  //   name: "4990Clientess",
  //   component: vue4990,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/general/inicio",
  //   name: "Generals",
  //   component: General,
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/colaborador/datos",
  //   name: "Colaborador",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/datos",
  //   name: "Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5990/datos",
  //   name: "5990-Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5690/datos",
  //   name: "5690-Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  {
    path: "/NqKa5MHGvHuMko5/datos",
    name: "5990Clientes",
    meta: {
      title: 'SOAP BANCOESTADO', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP BANCOESTADO'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  // {
  //   path: "/toCSq1UEhGaZp4z/datos",
  //   name: "5690Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/d6uaCqohiUk0UWW/datos",
  //   name: "5490Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/MlHaYAwEjhNPs5j/datos",
  //   name: "5190Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/vgqjYnJGvLfI6iO/datos",
  //   name: "4990Clientes",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/datos",
  //   name: "General",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/colaborador/resumen",
  //   name: "Colaboradorss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/resumen",
  //   name: "Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5990/resumen",
  //   name: "5990-Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5690/resumen",
  //   name: "5690-Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  {
    path: "/NqKa5MHGvHuMko5/resumen",
    name: "5990Clientesss",
    meta: {
      title: 'SOAP BANCOESTADO', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP BANCOESTADO'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  // {
  //   path: "/toCSq1UEhGaZp4z/resumen",
  //   name: "5690Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/d6uaCqohiUk0UWW/resumen",
  //   name: "5490Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/MlHaYAwEjhNPs5j/resumen",
  //   name: "5190Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/vgqjYnJGvLfI6iO/resumen",
  //   name: "4990Clientesss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/resumen",
  //   name: "Generalss",
  //   meta: {
  //     title: 'SOAP BANCOESTADO', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP BANCOESTADO'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/colaborador/finish",
  //   name: "Colaboradorsss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/finish",
  //   name: "Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/finish",
  //   name: "Generalsss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5990/finish",
  //   name: "5990-Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/5690/finish",
  //   name: "5690-Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  {
    path: "/NqKa5MHGvHuMko5/finish",
    name: "5990Clientessss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  // {
  //   path: "/toCSq1UEhGaZp4z/finish",
  //   name: "5690Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/d6uaCqohiUk0UWW/finish",
  //   name: "5490Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/MlHaYAwEjhNPs5j/finish",
  //   name: "5190Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/vgqjYnJGvLfI6iO/finish",
  //   name: "4990Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  {
    path: "/consulta",
    name: "Consultar",
    meta: {
      title: 'SOAP BANCOESTADO', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP BANCOESTADO'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP BANCOESTADO'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConsultaView.vue"),
  },
  {
    path: "/error",
    name: "Erorr",
    meta: {
      title: 'SOAP SEGUROFACIL', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP SEGUROFACIL'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP SEGUROFACIL'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Error.vue"),
  },
  {
    path: "/info",
    name: "Info",
    meta: {
      title: 'SOAP SEGUROFACIL', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP SEGUROFACIL'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP SEGUROFACIL'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/ErrorNote.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
