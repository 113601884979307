<template>
  <div :class="backgroundView">

    <br>
    <!-- <div :class="bannerView">SEGURO OBLIGATORIO SOAP 2024</div> -->
    <div :class="bannerView"></div>

    <br><br>
    <br v-if="screen <= 800">
    <br v-if="screen <= 800">
    <div :class="cartView">
      <CarritoCompra id="home_1" :patentes="patentes" :e1="e1" @reload="reaload()"></CarritoCompra>
    </div>

    <div v-if="loadSoap" class="text-center">
      <br><br><br><br><br>
      <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
      <br><br>
      Cargando
    </div>
    <div v-if="loadSoap == false" :class="topView">
      <v-form class="text-center" ref="form" v-model="valid">
        <br><br>

        <div v-if="newCar == true">
          <div :class="titleNewCarView">
            <b :class="title1NewCarView">Agrega otro <span :class="title2NewCarView">vehículo</span></b>
          </div>

          <v-container :class="containerStyleView">
            <v-col :class="colView">
              <div style="max-width: 500px; width: 100%;">
                <label :class="patentLabelNewCarView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldNewCarView" v-model="carData.patente" :rules="patenteRules" maxlength="6" minlength="5"
                  label="Ej: ABDC12" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 800">

              <div style="max-width: 500px; width: 100%;"> 
                <label :class="mailLabelNewCarView">Ingresa tu correo</label>
                <v-text-field :class="mailFieldNewCarView" v-model="propietarioData.email" label="Ej: miemail@mail.com"
                  :rules="emailRules" @change="emailValidate()" required solo></v-text-field>
              </div>
            </v-col>
            <br><br>
          </v-container>

          <div :class="captchaNewCarView">
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
          </div>

          <br>
          <div :class="buttonsNewCarView" style="margin:auto;">
            <v-btn :class="btnBackToPayView" @click="btnBackToPay()">Volver al pago</v-btn>
            <v-btn :class="btnSearchNewCarView" @click="validate()">Buscar</v-btn>
          </div>

          <br>

          <!-- <v-btn v-if="patentes.length != 0" class="btnBack" @click="returnPago()">Volver al pago</v-btn> -->

        </div>

        <div v-else class="title" >
          
          <v-container :class="containerStyleView">
            <v-col :class="colView">
              <div style="max-width: 500px; width: 100%;">
                <label :class="patentLabelView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldView" v-model="carData.patente" :rules="patenteRules" maxlength="6" minlength="5" label="EJ: ABDC12"
                  hide-details="auto" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 800">

              <div style="max-width: 500px; width: 100%;margin-top: -0.1%;">
                <label :class="mailLabelView">Ingresa tu correo</label>
                <v-text-field :class="mailFieldView" v-model="propietarioData.email" label="Ej: miemail@mail.com"
                  :rules="emailRules" @change="emailValidate()" required solo></v-text-field>
              </div>

            </v-col>
            <br><br>
          </v-container>
          <br>

          <div :class="captchaView">
            <vue-recaptcha id="captcha_1" ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
          </div>

          <br>

          <v-btn :class="btnSearchView" id="avanza_1" @click="validate()">BUSCAR</v-btn>

          <br>
          <div class="space"></div>


        </div>




      </v-form>
    </div>

    <br v-if="screen > 800">



    <div v-if="loadSoap == false" class="sub">


      <!-- INICIO WEB -->

      <v-col v-if="screen > 800" class="text-center"
        style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center;  max-width: 80%; margin: auto;">

        <div v-if="loadPlans == false" style="margin:auto;" class="infoContenedor">
          <br>
          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.car.price) }}
              </label>

            </div>
          </div>

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectangulo">
              <br>

              <v-img contain :src="Auto" max-width="75" class="images" />

              <br>
              <div class="tituloLargoBox">
                <label class="tituloLargo">
                  {{ plans.soap.car.name }}
                </label>
              </div>
            </div>
          </div>
          
        </div>

        <div v-if="loadPlans == false" style="margin:auto;" class="infoContenedor">

          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.camioneta.price) }}
              </label>

            </div>
          </div>
          
          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectangulo">
              <br>

              <v-img contain :src="Camioneta" max-width="75" class="images" />

              <br>

              <div class="tituloLargoBoxCamioneta">
                <label class="tituloLargo">
                  {{ plans.soap.camioneta.name }}
                </label>
              </div>
            </div>
          </div>

        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;" class="infoContenedor">
          <br>
          
          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.moto.price) }}
              </label>

            </div>
          </div>

          <br><br><br>

          
          <div class="rectangulo-container">
            <div class="rectangulo">
              <br>

              <v-img contain :src="Moto" max-width="65" class="imagesMoto" />

              <br>
              <div class="titulo3MotoBox">
                <label class="titulo3Moto">
                  {{ plans.soap.moto.name }}
                </label>
            </div>
            </div>
          </div>
        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;" class="infoContenedor">
          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.arrastre.price) }}
              </label>

            </div>
          </div>
          
          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectangulo">
              <br>

              <v-img contain :src="Carro" max-width="60" class="imagesCarro" />

              <br>

              <div class="tituloLargoArrasteBox">
                <label class="tituloLargo">
                  {{ plans.soap.arrastre.name }}
                </label>
              </div>

            </div>
          </div>

        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;" class="infoContenedor">
          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.bus.price) }}
              </label>

            </div>
          </div>          

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectangulo">
              <br>

              <v-img contain :src="Minibus" max-width="65" class="imagesMiniBus" />

              <br>
              <div class="titulo3BusBox">
                <label class="titulo3">
                  {{ plans.soap.bus.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </v-col>


      <!-- Fin Web -->

      <!-- INICIO MOVIL -->
      <br v-if="screen <= 800">
      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

        <div v-if="loadPlans == false" style="margin-left: -4%" :class="carBox">
          <br>
          
          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.car.price) }}
              </label>

            </div>
          </div>

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectanguloMobile">
              <br>

              <v-img contain :src="Auto" max-width="65" class="images2" />

              <br>
              <div class="tituloLargo2Box">
                <label class="tituloLargo2">
                  {{ plans.soap.car.name }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loadPlans == false" style="margin-left:6%;margin-right:-4%" :class="carBox">

          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.camioneta.price) }}
              </label>

            </div>
          </div>

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectanguloMobile">
              <br>

              <v-img contain :src="Camioneta" max-width="65" class="images2" />

              <br>
              <div class="tituloLargo3BoxCamioneta">
                <label class="tituloLargo3">
                  {{ plans.soap.camioneta.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <br>

      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">
        <div v-if="loadPlans == false" style="margin-left: -4%" :class="carBox">
          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.moto.price) }}
              </label>

            </div>
          </div>          

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectanguloMobile">
              <br>

              <v-img contain :src="Moto" max-width="55" class="images3" />

              <br>
              <div class="titulo2Box">
                <label class="titulo2">
                  {{ plans.soap.moto.name }}
                </label>
              </div>
            </div>
          </div>

        </div>

        <div v-if="loadPlans == false" style="margin-left:6%;margin-right:-4%" :class="carBox">
          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.arrastre.price) }}
              </label>

            </div>
          </div>
          
          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectanguloMobile">
              <br>

              <v-img contain :src="Carro" max-width="55" class="images4" />

              <br>
              <div class="tituloLargo3Box2">
                <label class="tituloLargo3">
                  {{ plans.soap.arrastre.name }}
                </label>
              </div>
            </div>
          </div>

        </div>
      </v-col>

      <br>


      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">
        <div v-if="loadPlans == false" style="margin:auto;" :class="carBox">
          <br>

          <div class="subrectangulo-container">
            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(plans.soap.bus.price) }}
              </label>

            </div>
          </div>

          <br><br><br>

          <div class="rectangulo-container">
            <div class="rectanguloMobile">
              <br>

              <v-img contain :src="Minibus" max-width="55" class="images5" />

              <br>
              <div class="titulo2Box2">
                <label class="titulo2">
                  {{ plans.soap.bus.name }}
                </label>
              </div>

            </div>
          </div>
        </div>
      </v-col>

      <!-- FIN MOVIL -->

      <v-col :class="titles" class="text-center">
        <div :class="titleView">
            <b> <span style="color:#2D387C"> Vigencia desde el </span> <span style="color:#EE811E"> 1 de abril del 2024 al 31 de marzo de 2025</span></b>
        </div>
      </v-col>

      <br>
      <div class="text-paths">
        *Valor SOAP auto, jeep y stationwagon $5.990. Valor a pagar por el contratante $5.490. 
        El Contratante otorga mandato expreso a BancoEstado para que en su nombre y representación entere a Southbridge Compañía de Seguros Generales S.A. el monto de $500 por concepto de diferencia de prima del SOAP.
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import Auto from "@/assets/images/automovil-bancoestado.png";
import Camioneta from "@/assets/images/camioneta-bancoestado.png";
import Carro from "@/assets/images/carro-bancoestado.png";
import Minibus from "@/assets/images/minibus-bancoestado.png";
import Moto from "@/assets/images/moto-bancoestado.png";
import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";
import swal from 'sweetalert';

export default {
  name: "InitForm",
  components: {
    VueRecaptcha,
    CarritoCompra,
  },
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    backgroundView: null,
    bannerView: null,
    topView: null,
    title1View: null,
    title2View: null,
    cartView: null,
    containerStyleView: null,
    colView: null,
    patentFieldView: null,
    mailFieldView: null,
    patentLabelView: null,
    captchaView: null,
    btnSearchView: null,
    btnFindSoapView: null,
    carBox: null,
    titleView: null,
    btnBackToPayView: null,
    title2NewCarView: null,
    titleNewCarView: null,
    patentFieldNewCarView: null,
    mailFieldNewCarView: null,
    patentLabelNewCarView: null,
    mailLabelNewCarView: null,
    captchaNewCarView: null,
    title1NewCarView: null,
    btnSearchNewCarView: null,
    titles: null,
    loadSoap: false,
    loadPlans: true,
    e1: 0,
    newCar: false,
    patentes: [],
    excluyentes: [],
    patenteUtils: null,
    loadingData: true,
    keygen: null,
    Auto,
    Camioneta,
    Carro,
    Minibus,
    Moto,
    captcha: null,
    screen: window.screen.width,

    carData: {
      patente: null,
      year: null,
      type: null,
      marca: null,
      model: null,
      motor: null,
    },
    propietarioData: {
      rut: null,
      nombres: null,
      firtlastname: null,
      secondlastname: null,
      email: null,
      phoneNumber: null,
    },
    plans: {},
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => (v && v.length <= 6) || 'La patente debe tener máximo 6 caracteres',
      (v) => (v && v.length > 4) || 'La patente debe tener mínimo 5 caracteres',
      (v) => /^[^\s]+$/.test(v) || 'La patente no puede contener espacios',
      (v) => /^[A-Za-z0-9]+$/.test(v) || 'La patente solo puede contener letras y números',
    ],
  }),
  methods: {
    btnBackToPay(){
      window.location.href = process.env.VUE_APP_DOMINIO + 'd6uaCqohiUk0UWW/resumen?keygen=' + this.keygen 
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async reaload() {
      const home_1 = document.getElementById('home_1');
      location.reload();
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      this.$router.push({ path: "/consulta" });
    },
    async validate() {
      const avanza_1 = document.getElementById('avanza_1');
      if (this.$refs.form.validate() && this.captcha == true){
        localStorage.setItem('path', 'd6uaCqohiUk0UWW')
        this.loadSoap = true
        var largoPatente = this.carData.patente.length
        if (largoPatente != 6) {
          var newPatente = this.carData.patente.match(/[a-z]+|[^a-z]+/gi)
          this.carData.patente = newPatente[0] + '0' + newPatente[1]
        }
        if (this.excluyentes.includes(this.carData.patente) == true) {
          swal({
            title: "Patente duplicada!",
            text: "Ya ha ingresado esta patente al carrito de compra",
            icon: "info",
            button: "Ok",
          });
          this.loadSoap = false
        } else {
          var patenteAprobada = await ShieldService.consultasoapAprobado(this.carData.patente)
          if (patenteAprobada.status == 'ok') {
            swal({
              title: "Patente registrada!",
              text: "Esta patente ya cuenta con un seguro SOAP",
              icon: "info",
              button: "Ok",
            });
          } else {
            if (this.newCar == true) {
              localStorage.setItem('patente', this.carData.patente);
              localStorage.setItem('email', this.propietarioData.email);
              localStorage.setItem('typePlan', 'soap-5490-becs');
              window.location.href = process.env.VUE_APP_DOMINIO + 'd6uaCqohiUk0UWW/datos' + '?newCar=' + this.newCar;
            } else {
              localStorage.setItem('patente', this.carData.patente);
              localStorage.setItem('email', this.propietarioData.email);
              localStorage.setItem('typePlan', 'soap-5490-becs');
              window.location.href = process.env.VUE_APP_DOMINIO + 'd6uaCqohiUk0UWW/datos';
            }
          }
          this.loadSoap = false
        }
      }
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    verifyMethod() {
      const captcha_1 = document.getElementById('captcha_1');
      this.captcha = true
    },
    Mayus() {
      this.carData.patente = this.carData.patente.replace(/\s+/g, '');
      this.carData.patente = this.carData.patente.toUpperCase()
    },
    emailValidate() {
      this.propietarioData.email = this.propietarioData.email.replace(/\s+/g, '');
    },
    returnPago() {
      var external = localStorage.getItem('externalID')
      window.location.href = process.env.VUE_APP_DOMINIO + 'd6uaCqohiUk0UWW/resumen?type=soap-5490-becs' + '&keygen=' + this.keygen + '&external=' + external
    }
  },
  async mounted() {
    const query = this.$route.query;
    var shieldRes = await ShieldService.getPlans('soap-5490-becs')
    if (shieldRes.status == 'ok') {
      this.plans = shieldRes.data[0]
      this.loadPlans = false
    }
    if (query.newCar == 'true') {
      this.newCar = true
      this.keygen = localStorage.getItem('keygen')
      await ShieldService.getCars(this.keygen).then((response) => {
        if (response.status == 'ok') {
          response.data.forEach(car => {
            this.patentes.push(car)
            this.excluyentes.push(car.patenteNumber)
          });
        }
      })
      this.loadingData = false
    }
    if (this.screen > 800) {
      this.backgroundView = 'backgroundView'
      this.bannerView = 'bannerView'
      this.topView = 'topView'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.cartView = 'cartView'
      this.containerStyleView = 'containerStyleView'
      this.colView = 'colView'
      this.patentFieldView = 'patentFieldView'
      this.mailFieldView = 'mailFieldView'
      this.patentLabelView = 'patentLabelView'
      this.mailLabelView = 'mailLabelView'
      this.captchaView = 'captchaView'
      this.btnSearchView = 'btnSearchView'
      this.carBox = 'carBox'
      this.btnFindSoapView = 'btnFindSoapView'
      this.titleView = 'titleView'
      this.btnBackToPayView = 'btnBackToPayView'
      this.title2NewCarView = 'title2NewCarView'
      this.titleNewCarView = 'titleNewCarView'
      this.patentFieldNewCarView = 'patentFieldNewCarView'
      this.mailFieldNewCarView = 'mailFieldNewCarView'
      this.patentLabelNewCarView = 'patentLabelNewCarView'
      this.mailLabelNewCarView = 'mailLabelNewCarView'
      this.captchaNewCarView = 'captchaNewCarView'
      this.buttonsNewCarView = 'buttonsNewCarView'
      this.title1NewCarView = 'title1NewCarView'
      this.btnSearchNewCarView = 'btnSearchNewCarView'
      this.titles = 'titles'

    } else if (this.screen <= 800) {
      this.backgroundView = 'backgroundView2'
      this.bannerView = 'bannerView2'
      this.topView = 'topView2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.cartView = 'cartView2'
      this.containerStyleView = 'containerStyleView2'
      this.colView = 'colView2'
      this.patentFieldView = 'patentFieldView2'
      this.mailFieldView = 'mailFieldView2'
      this.patentLabelView = 'patentLabelView2'
      this.mailLabelView = 'mailLabelView2'
      this.captchaView = 'captchaView2'
      this.btnSearchView = 'btnSearchView2'
      this.carBox = 'carBox2'
      this.titleView = 'titleView2'
      this.btnBackToPayView = 'btnBackToPayView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.title2NewCarView = 'title2NewCarView2'
      this.titleNewCarView = 'titleNewCarView2'
      this.patentFieldNewCarView = 'patentFieldNewCarView2'
      this.mailFieldNewCarView = 'mailFieldNewCarView2'
      this.patentLabelNewCarView = 'patentLabelNewCarView2'
      this.mailLabelNewCarView = 'mailLabelNewCarView2'
      this.captchaNewCarView = 'captchaNewCarView2'
      this.buttonsNewCarView = 'buttonsNewCarView2'
      this.title1NewCarView = 'title1NewCarView2'
      this.btnSearchNewCarView = 'btnSearchNewCarView2'
      this.titles = 'titles2'


    }
  },
};
</script>

<style lang="css" scoped>
.titleView {
  margin-top: -3%;
  margin-bottom: 0.4%;
  font-family: 'Inter', sans-serif ;
  font-size: 21px;
}
.titleView2 {
  margin-bottom: -2%;
  width: 86%;
  font-size: 21px;
  margin: auto;

  
}

.titleNewCarView {
  margin-top: -5.2%;
  margin-bottom: 2%;
}

.titleNewCarView2 {
  margin-top: -4%;
  margin-left: -27%;
  margin-bottom: 4%;
}

.containerStyleView{
  justify-content: center; 
  display: flex;
  margin-left: auto; 
  padding-top: -4%;
  max-width: 700px !important;
  width: 100% !important;
  margin-top: -3%;
}

.containerStyleView2 {
  justify-content: center;
  display: block;
  margin-left: auto;
  /* margin-left: -14%; */

}

.obligatorio {
  text-align: center;
  font: normal normal normal 30px/36px Arial;
  letter-spacing: 0px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.vigencia {
  text-align: center;
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0px;
  color: #EF7C00;
  opacity: 1;
}

.topView {
  background: #FFF 0% 0% no-repeat padding-box !important;
  box-shadow: 7px 9px 9.9px 2px #00000029;
  opacity: 1;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-left: 23%;
  margin-top: -3.5%;
  margin-bottom: -0.4%;
}

.topView2 {
  /* background: #FFF 0% 0% no-repeat padding-box !important; */
  opacity: 1;
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.sub {
  /* background: #F3F3F3 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.titulo {
  text-align: left;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #2D387C;
  opacity: 1;
  margin-top: 16%;
  margin-left: 8%;
  width: 70%;
}
.titulo3 {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #2C3D46;
  opacity: 1;
  margin-left: 9%;
  line-height: 20px;
  font-weight: 500;
}

.titulo3Moto {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #2C3D46;
  opacity: 1;
  font-weight: 500;
  margin-left: -3%;
  line-height: 20px;

}

.titulo2 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #2C3D46;
  opacity: 1;
  margin-top: 14%;
  margin-left: 11%;
}

.tituloLargoBox{
  margin-left: 7.5%;
  width: 83%;
  margin-top: -16.5%;
}
.tituloLargoBoxCamioneta{
  margin-left: 13%;
  width: 70%;
  margin-top: -16.5%;
}
.titulo3BusBox{
  margin-left: 4.5%;
  width: 82%;
  margin-top: -8%;
}
.tituloLargoArrasteBox{
  margin-left: 7.5%;
  width: 80%;
  margin-top: -14%;
}
.tituloLargo {
  text-align: center;
  font-size: 12px;
  color: #2C3D46;
  opacity: 1;
  font-weight: 500;
  line-height: 20px;
}

.tituloLargo2 {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #2C3D46;
  opacity: 1;
  margin: auto;
  width: 50%;
}
.tituloLargo3 {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #2C3D46;
  opacity: 1;
  margin: auto;
}


.tituloLargoCarro {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #2C3D46;
  opacity: 1;
  margin-left: 9%;
  width: 60%;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.96px;
  color: #FFF;
  opacity: 1;
  font-weight: bold;
  margin-top: -2%;
}

.rectangulo-container {
  margin-top: -42.5%;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  
}



.rectangulo {
  /* display: flex; */
  margin-top: -3%;
  width: 180px;
  height: 130px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -1%;
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.rectanguloMobile {
  margin-top: -2%;
  width: 180px;
  height: 120px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  /* margin-left: 3%; */
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.subrectangulo-container {
  margin-top: -12%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}



.subrectangulo {
  width: 200;
  height: 55px;
  opacity: 1;
  background-color: #EE811E;
  padding: 5%;
  margin-top: -3%;
}


.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -15%;
  font-size: 38px;
  color: #FFFFFF;
  margin-bottom: -2%;
}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -35%;
  font-size: 32px;
  color: #FFFFFF;
  margin-bottom: -9%;
  padding-left: 2%;
  padding-right: 2%;
}

.title1View {
  font-family: 'Inter', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 21px;

}

.title1View2 {
  font-family: 'Inter', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 20px;
  margin-left: -23%;
}

.title2View {
  margin-left: 1%;
  font-family: 'Inter', sans-serif;
  color: #EE811E;
  font-weight: bold;
  font-size: 21px;
}

.title2View2 {
  margin-left: -18%;
  font-family: 'Inter', sans-serif;
  color: #4F74E3;
  font-weight: bold;
  font-size: 20px;
  display: block;

}

.title1NewCarView {
  font-family: 'Inter', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 24px;
}

.title1NewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 20px;
  /* margin-left: -38%; */
  /* margin: auto; */
}

.title2NewCarView {
  margin-left: 1%;
  font-family: 'Inter', sans-serif;
  color: #EE811E;
  font-weight: bold;
  font-size: 24px;
}

.title2NewCarView2 {
  /* margin-left: 3%; */
  font-family: 'Inter', sans-serif;
  color: #EE811E;
  font-weight: bold;
  font-size: 20px;
}

.patentLabelView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -62%;
  font-size: 70%;
}

.patentLabelView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 50%;
  font-size: 80%;
}

.mailLabelView{
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -62%;
  font-size: 70%;
}

.mailLabelView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 50%;
  font-size: 80%;
}

.cartView {
  margin-top: 8%;
  margin-left: -7%;
}

.cartView2 {
  margin-left: 45%;
  margin-top: 8%;
  margin-bottom: -16%;
  
}

.images {
  margin-left: 25.5%;
  margin-top: -10.2%;
}
.imagesCarro {
  margin-left: 29%;
  margin-top: -5%;
}

.imagesMiniBus {
  margin-left: 30%;
  margin-top: -7%;
}

.imagesMoto {
  margin-left: 31%;
}

.images2 {
  margin-left: 28%;
  margin-top: -10%;
}
.images4 {
  margin-left: 30%;
  margin-top: -6%;
}
.images5 {
  margin-left: 31%;
  margin-top: -6%;
}

.captchaView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4%;
  margin-bottom: 1%;
}

.captchaView2 {
  margin-top: -21%;
  margin-left: 7%;
  margin-bottom: 3%;
}

.btnSearchView {
  width: 300px;
  height: 40px !important;
  background: #EE811E 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin-top: -4%;
}

.btnSearchView2 {
  width: 300px;
  height: 50px !important;
  background: #EE811E 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin: auto;
}

.btnBack {
  width: 398px;
  height: 60px !important;
  background: #0069B6 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px !important;
  color: white;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.btnFindSoapView {
  width: 284px;
  height: 40px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-top: -5%;
  font-size: 16px;
}

.btnFindSoapView2 {
  width: 284px;
  height: 50px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-top: 3%;
}

.carBox {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
}

.carBox2 {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
  width: 45%;
  /* margin-left: -1%; */
}

.backgroundView {
  background-color: #F3F3F3;
}

.backgroundView2 {
  background-color: #FFFFFF;
}

#app > div > main > div > div > div.topView > form{
  width: 100%;
}

.patentFieldView {
  width: 100%; /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;
  margin-top: -1.5%;

}

.patentFieldView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  text-transform: uppercase;
}

.mailFieldView {
  width: 100%; /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-top: -1.5%;
}

.mailFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.btnBackToPayView {
  width: 100%;
  max-width: 280px;
  height: 40px !important;
  background: #2D387C 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.btnBackToPayView2 {
  width: 300px;
  height: 50px !important;
  background: #2D387C 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin-top: 2%;
}

.colView {
  display: flex;
  gap: 10px; 
  margin: auto;
  justify-content: center; 
  align-items: center; 
}

.colView2 {
  display: block;
  margin: auto;
  /* margin-left: -20%; */
}

.patentFieldNewCarView {
  width: 100%; /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;
}

.patentFieldNewCarView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 35%; */
  margin: auto;
  text-transform: uppercase;
}

.mailFieldNewCarView {
  width: 100%; /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
}

.mailFieldNewCarView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin:auto;
  margin-bottom: -9%;
}

.patentLabelNewCarView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -61%;
  font-size: 88%;
}

.patentLabelNewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  /* margin-left: 15%; */
  margin: auto;
  font-size: 100%;
  margin-left: -56%;
}

.mailLabelNewCarView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -63%;
  font-size: 88%;
}

.mailLabelNewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin: auto;
  font-size: 100%;
  margin-left: -56%;
}

.captchaNewCarView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1%;
}

.captchaNewCarView2 {
  /* margin-top: -130%; */
  margin:auto;
  margin-left: 6%;
  margin-bottom: 4%;
}

.buttonsNewCarView {
  display: flex;
  margin-top: 2%;
  margin: auto;
  justify-content: center; 
  align-items: center; 
  gap: 30px; 

  
}

.buttonsNewCarView2 {
  /* display:flex;  */
  margin-top: 2%;
  margin: auto;
  margin-bottom: -4%;
}

.infoContenedor {
  width: 170px;
}

.space {
  /* margin-top: -3%; */
  margin-bottom: 2%;
}

.btnSearchNewCarView {
  width: 100%;
  max-width: 280px;
  height: 40px !important;
  background: #EE811E 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

}

.btnSearchNewCarView2 {
  width: 300px;
  height: 50px !important;
  background: #EE811E 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin: auto;
  margin-bottom: 8%;
  margin-top: 12%;
}
.html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main {
    flex: none !important;
}

.v-main {
    display: flex;
    flex: none !important;
    max-width: 100%;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.text-paths{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  width: 90%;
  margin: auto;
  text-align: center
}

.titulo3MotoBox{
  margin-top: -2%;
}
.titles{
  margin-bottom: -3%;
}
.titles2{
  margin-bottom: -8%;
}
.tituloLargo2Box{
  width: 90%;
  margin-top: -17%;
  margin-left: 2%;
}
.tituloLargo3Box{
  width: 79%;
  margin-top: -17%;
  margin-left: 2%;
}
.tituloLargo3BoxCamioneta{
  width: 79%;
  margin-top: -17%;
  margin-left: 7%;
}
.tituloLargo3Box2{
  width: 90%;
  margin-top: -14%;
  margin-left: 3%;
}

.images3{
  margin-left: 32%;
}
.titulo2Box{
  margin-left: -20%;
  margin-top: -8.7%;
}
.titulo2Box2{
  margin-left: -17%;
  margin-top: -12%;
}
</style>