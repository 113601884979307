<template>
  <v-app>


    <v-app-bar
      v-if="screen > 800 && (pathname != '/clientes/datos' && pathname != '/clientes/resumen' && pathname != '/clientes/finish')"
      :class="navVar" class="appBarStyle">

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-app-bar
      v-if="screen > 800 && (pathname == '/clientes/datos' || pathname == '/clientes/resumen' || pathname == '/clientes/finish')"
      :class="navVar" class="appBarStyle3">

      <v-spacer></v-spacer>

    </v-app-bar>




    <v-app-bar v-if="screen <= 800" :class="navVar" style="height: 46.5vw ;" class="appBarStyle2">

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>


    <Footer />

  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
// import Tarjetas from "@/components/Tarjetas.vue";
//import TarjetasZaping from "@/components/TarjetaZapping.vue";
import MovilClientes from "@/assets/images/MovilClientes.jpg";
import MovilGeneral from "@/assets/images/MovilGeneral.jpg";
import MovilColaborador from "@/assets/images/MovilColaborador.jpg";
import path from "path";

export default {
  components: {
    Footer,
  },
  data: () => ({
    MovilClientes,
    MovilGeneral,
    MovilColaborador,
    screen: window.screen.width,
    navVar: '',
    pathname: window.location.pathname,
    camapana: '',

  }),
  mounted() {
    if (this.screen > 800) {
      switch (this.pathname) {

        // case '/5990/inicio':
        // this.navVar = 'navVarConsultaFinish5990'
        //   break;

        // case '/5990/datos':
        // this.navVar = 'navVarConsultaFinish5990'
        //   break;

        // case '/5990/resumen':
        // this.navVar = 'navVarConsultaFinish5990'
        //   break;

        // case '/5990/finish':
        // this.navVar = 'navVarConsultaFinish5990'
        //   break;

        // case '/5690/inicio':
        // this.navVar = 'navVarConsultaFinish5690'
        //   break;

        // case '/5690/datos':
        // this.navVar = 'navVarConsultaFinish5690'
        //   break;

        // case '/5690/resumen':
        // this.navVar = 'navVarConsultaFinish5690'
        //   break;

        // case '/5690/finish':
        // this.navVar = 'navVarConsultaFinish5690'
        //   break;

        case '/NqKa5MHGvHuMko5/inicio':
          this.navVar = 'navVarConsultaFinish5990'
          break;

        case '/NqKa5MHGvHuMko5/datos':
          this.navVar = 'navVarConsultaFinish5990'
          break;

        case '/NqKa5MHGvHuMko5/resumen':
          this.navVar = 'navVarConsultaFinish5990'
          break;

        case '/NqKa5MHGvHuMko5/finish':
          this.navVar = 'navVarConsultaFinish5990'
          break;

        case '/toCSq1UEhGaZp4z/inicio':
          this.navVar = 'navVarConsultaFinish5690'
          break;

        case '/toCSq1UEhGaZp4z/datos':
          this.navVar = 'navVarConsultaFinish5690'
          break;

        case '/toCSq1UEhGaZp4z/resumen':
          this.navVar = 'navVarConsultaFinish5690'
          break;

        case '/toCSq1UEhGaZp4z/finish':
          this.navVar = 'navVarConsultaFinish5690'
          break;

        case '/d6uaCqohiUk0UWW/inicio':
          this.navVar = 'navVarConsultaFinish5490'
          break;

        case '/d6uaCqohiUk0UWW/datos':
          this.navVar = 'navVarConsultaFinish5490'
          break;

        case '/d6uaCqohiUk0UWW/resumen':
          this.navVar = 'navVarConsultaFinish5490'
          break;

        case '/d6uaCqohiUk0UWW/finish':
          this.navVar = 'navVarConsultaFinish5490'
          break;

        case '/MlHaYAwEjhNPs5j/inicio':
          this.navVar = 'navVarConsultaFinish5190'
          break;

        case '/MlHaYAwEjhNPs5j/datos':
          this.navVar = 'navVarConsultaFinish5190'
          break;

        case '/MlHaYAwEjhNPs5j/resumen':
          this.navVar = 'navVarConsultaFinish5190'
          break;

        case '/MlHaYAwEjhNPs5j/finish':
          this.navVar = 'navVarConsultaFinish5190'
          break;

        case '/vgqjYnJGvLfI6iO/inicio':
          this.navVar = 'navVarConsultaFinish4990'
          break;

        case '/vgqjYnJGvLfI6iO/datos':
          this.navVar = 'navVarConsultaFinish4990'
          break;

        case '/vgqjYnJGvLfI6iO/resumen':
          this.navVar = 'navVarConsultaFinish4990'
          break;

        case '/vgqjYnJGvLfI6iO/finish':
          this.navVar = 'navVarConsultaFinish4990'
          break;

        case '/clientes/inicio':
          this.navVar = 'navVarCliente'
          break;

        case '/clientes/datos':
          this.navVar = 'navVarCliente'
          break;

        case '/clientes/resumen':
          this.navVar = 'navVarCliente'
          break;

        case '/clientes/finish':
          this.navVar = 'navVarCliente'
          break;

        case '/consulta':
          this.navVar = 'navVarConsultas'
          break;

        case '/error':
          this.navVar = 'navVarConsultas'
          break;

        case '/general/inicio':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/general/datos':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/general/resumen':
          this.navVar = 'navVarConsultaFinish'
          break;

        // case '/colaborador/inicio':
        //   this.navVar = 'navVarColaborador'
        //   break;

        // case '/colaborador/datos':
        //   this.navVar = 'navVarColaborador'
        //   break;

        // case '/colaborador/resumen':
        //   this.navVar = 'navVarColaborador'
        //   break;

        // case '/colaborador/consulta':
        // this.navVar = 'navVarColaborador'
        // break;

        // case '/colaborador/finish':
        //   this.navVar = 'navVarConsultaFinish'
        //   break;

        default:
          this.navVar = 'navVarConsultas'
          break;
      }
    } else if (this.screen < 800) {
      switch (this.pathname) {

        case '/5990/inicio':
          this.navVar = 'navVar5990Movil'
          break;

        // case '/5990/datos':
        // this.navVar = 'navVar5990Movil'
        //   break;

        // case '/5990/resumen':
        // this.navVar = 'navVar5990Movil'
        //   break;

        // case '/5990/finish':
        // this.navVar = 'navVar5990Movil'
        //   break;

        // case '/5690/inicio':
        // this.navVar = 'navVar5690Movil'
        //   break;

        // case '/5690/datos':
        // this.navVar = 'navVar5690Movil'
        //   break;

        // case '/5690/resumen':
        // this.navVar = 'navVar5690Movil'
        //   break;

        // case '/5690/finish':
        // this.navVar = 'navVar5690Movil'
        //   break;

        case '/NqKa5MHGvHuMko5/inicio':
          this.navVar = 'navVar5990Movil'
          break;

        case '/NqKa5MHGvHuMko5/datos':
          this.navVar = 'navVar5990Movil'
          break;

        case '/NqKa5MHGvHuMko5/resumen':
          this.navVar = 'navVar5990Movil'
          break;

        case '/NqKa5MHGvHuMko5/finish':
          this.navVar = 'navVar5990Movil'
          break;

        case '/toCSq1UEhGaZp4z/inicio':
          this.navVar = 'navVar5690Movil'
          break;

        case '/toCSq1UEhGaZp4z/datos':
          this.navVar = 'navVar5690Movil'
          break;

        case '/toCSq1UEhGaZp4z/resumen':
          this.navVar = 'navVar5690Movil'
          break;

        case '/toCSq1UEhGaZp4z/finish':
          this.navVar = 'navVar5690Movil'
          break;

        case '/d6uaCqohiUk0UWW/inicio':
          this.navVar = 'navVar5490Movil'
          break;

        case '/d6uaCqohiUk0UWW/datos':
          this.navVar = 'navVar5490Movil'
          break;

        case '/d6uaCqohiUk0UWW/resumen':
          this.navVar = 'navVar5490Movil'
          break;

        case '/d6uaCqohiUk0UWW/finish':
          this.navVar = 'navVar5490Movil'
          break;

        case '/MlHaYAwEjhNPs5j/inicio':
          this.navVar = 'navVar5190Movil'
          break;

        case '/MlHaYAwEjhNPs5j/datos':
          this.navVar = 'navVar5190Movil'
          break;

        case '/MlHaYAwEjhNPs5j/resumen':
          this.navVar = 'navVar5190Movil'
          break;

        case '/MlHaYAwEjhNPs5j/finish':
          this.navVar = 'navVar5190Movil'
          break;

        case '/vgqjYnJGvLfI6iO/inicio':
          this.navVar = 'navVar4990Movil'
          break;

        case '/vgqjYnJGvLfI6iO/datos':
          this.navVar = 'navVar4990Movil'
          break;

        case '/vgqjYnJGvLfI6iO/resumen':
          this.navVar = 'navVar4990Movil'
          break;

        case '/vgqjYnJGvLfI6iO/finish':
          this.navVar = 'navVar4990Movil'
          break;

        case '/clientes/inicio':
          this.navVar = 'navVarCientesMovil'
          break;

        case '/clientes/datos':
          this.navVar = 'navVarCientesMovil'
          break;

        case '/clientes/resumen':
          this.navVar = 'navVarCientesMovil'
          break;

        case '/clientes/finish':
          this.navVar = 'navVarCientesMovil'
          break;

        case '/general/inicio':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/general/datos':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/general/resumen':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/colaborador/inicio':
          this.navVar = 'navVarColaboradorMovil'
          break;

        case '/colaborador/datos':
          this.navVar = 'navVarColaboradorMovil'
          break;

        case '/colaborador/resumen':
          this.navVar = 'navVarColaboradorMovil'
          break;

        case '/consulta':
          this.navVar = 'navVarConsultasMovil'
          break;

        case '/error':
          this.navVar = 'navVarConsultasMovil'
          break;

        case '/finish':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        default:
          this.navVar = 'navVarConsultasMovil'
          break;

      }
    }
  }
};
</script>

<style>
.appBarStyle {
  height: 310px !important;
  box-shadow: 0px 0px 0px !important;
  background-color: #F3F3F3 !important;
}

.appBarStyle2 {
  /* height: 220px !important;  */
  box-shadow: 0px 0px 0px !important;
  background-color: #FFFFFF !important;
}

.appBarStyle3 {
  height: 310px !important;
  box-shadow: 0px 0px 0px !important;
  background-color: #FFFFFF !important;
}

.appBarStyle4 {
  height: 310px !important;
  box-shadow: 0px 0px 0px !important;
  background-color: #F3F3F3 !important;
}

.navVarClientes {
  background-image: url("~@/assets/images/banner-bancoestado.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarGeneral {
  background-image: url("~@/assets/images/WebGeneral.jpg");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

}

.navVarColaborador {
  background-image: url("~@/assets/images/WebColaborador.jpg");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish {
  background-image: url("~@/assets/images/banner-bancoestado.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinishCliente {
  background-image: url("~@/assets/images/banner-bancoestado-web.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarCliente {
  background-image: url("~@/assets/images/Flujo-Venta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultas {
  background-image: url("~@/assets/images/Consulta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish5990 {
  background-image: url("~@/assets/images/Flujo-Venta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish5690 {
  background-image: url("~@/assets/images/Consulta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish5490 {
  background-image: url("~@/assets/images/Consulta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish5190 {
  background-image: url("~@/assets/images/Consulta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish4990 {
  background-image: url("~@/assets/images/Consulta-Desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarClientesMovil {
  background-image: url("~@/assets/images/banner-bancoestado.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

}

.navVar5990Movil {
  background-image: url("~@/assets/images/Flujo-Venta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVar5690Movil {
  background-image: url("~@/assets/images/Consulta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVar5490Movil {
  background-image: url("~@/assets/images/Consulta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVar5190Movil {
  background-image: url("~@/assets/images/Consulta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVar4990Movil {
  background-image: url("~@/assets/images/Consulta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVarGeneralMovil {
  background-image: url("~@/assets/images/banner-bancoestado.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

}

.navVarColaboradorMovil {
  background-image: url("~@/assets/images/banner-bancoestado-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

}

.navVarConsultaFinishMovil {
  background-image: url("~@/assets/images/banner-bancoestado-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVarCientesMovil {
  background-image: url("~@/assets/images/Flujo-Venta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.navVarConsultasMovil {
  background-image: url("~@/assets/images/Consulta-Mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.movil {
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  width: 100%;
  height: 120%;
}




@font-face {
  font-family: "Scotia_Bd";
  src: local("Scotia_Bd"),
    url(/fonts/Scotia_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_BdIt";
  src: local("Scotia_BdIt"),
    url(/fonts/Scotia_BdIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Headline";
  src: local("Scotia_Headline"),
    url(/fonts/Scotia_Headline.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_It";
  src: local("Scotia_It"),
    url(/fonts/Scotia_It.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Legal";
  src: local("Scotia_Legal"),
    url(/fonts/Scotia_Legal.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Lt";
  src: local("Scotia_Lt"),
    url(/fonts/Scotia_Lt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_LtIt";
  src: local("Scotia_LtIt"),
    url(/fonts/Scotia_LtIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Rg";
  src: local("Scotia_Rg"),
    url(/fonts/Scotia_Rg.otf) format("truetype");
}</style>