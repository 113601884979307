<template>
  <div>
   
    <div :class="footer" class="text-center">
      <br>
      <div :class="detalleBox" class="text-center">
        <label :class="detalle">Seguro Obligatorio de Accidente Personales (SOAP) 2024, para Auto, Station Wagon, Jeep, Camioneta, Furgón, Mini Bus Particular, Moto o Carro de Arrastre, intermediado por BancoEstado Corredores de Seguros S.A. La compañía que asume los riesgos es Southbridge Compañía de Seguros Generales S.A., bajo el código POL 3 2013 0487, depositado en la Comisión para el Mercado Financiero. Más detalles de promociones en www.bancoestado.cl. BancoEstado Corredores de Seguros S.A. es filial de BancoEstado.</label>
      </div>
      <div :class="image" class="text-center">

        <v-img v-if="screen>800" contain max-height="160px" :src="LogoSB" max-width="245" style=" margin: auto; " />
        <v-img v-else contain max-height="130px" :src="LogoSB" max-width="215" style=" margin: auto;" />

      </div>

    </div>

  </div>
</template>

<script>
import LogoSB from "@/assets/images/Logo SouthBridge-04 1.png";

export default {
  name: "Footer",
  mounted() {
  },
  data: () => ({
    LogoSB,
    path: null,
    puntos: null,
    screen: null,
    banner: null,
    texto: null,
    footer: null,
    detalle: null,
    detalleBox:null,
    image:null,
  }),
  mounted() {
    this.path = window.location.pathname;
    if (this.path == '/clientes/finish') {
      this.puntos = '7.000'
    } else if (this.path == '/colaborador/finish') {
      this.puntos = '10.000'
    }
    this.screen = window.screen.width
    if (this.screen > 800) {
      this.banner = 'text-center banner'
      this.texto = 'texto'
      this.footer = 'footer'
      this.detalle = 'detalle'
      this.detalleBox = 'detalleBox'
      this.image = 'image'
    } else {
      this.banner = 'text-center banner2'
      this.texto = 'texto2'
      this.footer = 'footer2'
      this.detalle = 'detalle2'
      this.detalleBox = 'detalleBox2'
      this.image = 'image2'
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.cobertura {
  text-align: center;

  letter-spacing: 0.3px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.detalle {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #FFF;
  opacity: 1;
  font-family: 'Inter', sans-serif;  
}
.detalle2 {
  text-align: justify;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #FFF;
  opacity: 1;
  font-family: 'Inter', sans-serif;  
}

.footer {
  height: 105%;
  background: #2D387C 0% 0% no-repeat padding-box;
}
.footer2 {
  height: 105%;
  background: #2D387C 0% 0% no-repeat padding-box;
}

.banner {
  height: 100%;
  background: #FFF;
}

.banner2 {
  height: 50%;
  background: #F3F3F3;
}

.texto {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 40px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.texto2 {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 20px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.butoon {
  width: 310px;
  height: 64px;
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.butoon2 {
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.theme--light.v-btn.v-btn--has-bg {
  background: #EF7C00 0% 0% no-repeat padding-box;
}
.image{
  margin-top: 0.5%;
}
.image2{
  margin-top: 2.5%;
}
.detalleBox{
  padding-left: 1%;
  padding-right: 1%;
  margin-top: -0.5%;
}
.detalleBox2{
  padding-left: 3%;
  padding-right: 3%;
  // margin-top: 1.5%;
  text-align: justify !important;
  margin-top: -1%;
}
</style>
